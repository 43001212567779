import DataTable from 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import './components/menu.js';

$(document).ready(function () {
    //from old front.js
    new DataTable('.product-datatable', {
        info: true,
        ordering: true,
        paging: true,
        responsive: true,
    });

    new DataTable('.product-page-datatable', {
        info: true,
        ordering: true,
        paging: true,
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: "/module/eventory/dashboardajax?action=datatable-product",
        "columns": [
            { "data": "image" },
            { "data": "product_name" },
            { "data": "ean13" },
            { "data": "reference" },
            { "data": "stock_location" },
            { "data": "quantity" },
            { "data": "action" }
            // Ajouter les autres colonnes si nécessaire
        ]
    });

    var breakageDatable = new DataTable('.breakage-page-datatable', {
        info: true,
        ordering: true,
        paging: true,
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: "/module/eventory/dashboardajax?action=datatable-logs",
        "columns": [
            { "data": "image" },
            { "data": "product_name" },
            { "data": "ean13" },
            { "data": "customer" },
            { "data": "action" },
            { "data": "date_add" },
            // Ajouter les autres colonnes si nécessaire
        ],
        "order": [[5, "desc"]]
    });

    // $('.select2_search_product').select2({
    //     ajax: {
    //         url: '/ajaxcore?action=get-products-by-ean',
    //         dataType: 'json',
    //         delay: 250,
    //     },
    //     placeholder: 'Export barecode product',
    //     minimumInputLength: 2,
    //     templateResult: formatSelect2Product,
    //     templateSelection: formatSelect2ProductSelection
    // }).on('select2:select', function (e) {
    //     // Proceed with AJAX request if validation passes
    //     var selectedProduct = e.params.data;
    //     $.ajax({
    //         type: "POST",
    //         url: "/ajaxcore",
    //         dataType: "json",
    //         data: {
    //             action: 'export-ean13',
    //             datas: selectedProduct,
    //         },
    //         success: function (response) {
    //             checkAjaxResponse(response);
    //         },
    //         error: function (request, status, error) {
    //             checkAjaxResponse(error);
    //         },
    //     });
    // });

    $(document).on('click', '.ajax-save-form', function (e) {
        e.preventDefault(); // Prevent default form submission

        // Perform validation first
        if (validateFormFields()) {
            //manageLoader('show');
            Pace.track(function () {
                // Proceed with AJAX request if validation passes
                $.ajax({
                    type: "POST",
                    url: "/ajaxcore",
                    dataType: "json",
                    data: {
                        action: 'save-configuration',
                        datas: $('#eventory-dashboard form:visible').serialize(),
                    },
                    success: function (response) {
                        checkAjaxResponse(response);
                    },
                    error: function (request, status, error) {
                        checkAjaxResponse(error);
                    },
                });
            });
        }
    });
    $(document).on('click', '.get_combination', function () {
        $('#blocModalCombination').html('');
        manageLoader('show');
        $.ajax({
            type: "POST",
            url: "/module/eventory/dashboardajax",
            dataType: "json",
            data: {
                action: 'get-combination',
                token: tokenAjaxGetCombination,
                id_product: $(this).attr('data-id-product'),
            },
            success: function (response) {
                checkAjaxResponse(response);
                $('#blocModalCombination').append(response.datas)
            },
            error: function (request, status, error) {
                checkAjaxResponse(error);
            },
        });
    })

    $(document).on('click', '.remove_product', function () {
        if (confirm('Voulez-vous vraiment supprimer ce produit?')) {
            manageLoader('show');
            let me = $(this);
            $.ajax({
                type: "POST",
                url: "/module/eventory/dashboardajax",
                dataType: "json",
                data: {
                    action: 'delete-product',
                    token: tokenAjaxGetCombination,
                    ean13: $(this).attr('data-ean13'),
                },
                success: function (response) {
                    checkAjaxResponse(response);
                    me.closest('tr').fadeOut();
                },
                error: function (request, status, error) {
                    checkAjaxResponse(error);
                },
            });
        }
    })


    $(document).on('click', '.remove_warehouse', function () {
        var me = $(this);
        if (confirm('Are you sure you want to remove this warehouse?')) {
            manageLoader('show');
            $.ajax({
                type: "POST",
                url: "/module/eventory/dashboardajax",
                dataType: "json",
                data: {
                    action: 'remove-warehouse',
                    token: tokenAjaxRemoveWarehouse,
                    id_warehouse: $(this).attr('data-id-warehouse'),
                },
                success: function (response) {
                    checkAjaxResponse(response);
                    me.closest('tr').fadeOut();
                },
                error: function (request, status, error) {
                    checkAjaxResponse(error);
                },
            });
        }
    });

    $(document).on('click', '#saveBreakage', function () {
        var me = $(this);
        let qty = me.closest('#modalBreakage').find('#ean13_breakage_quantity').val()
        manageLoader('show');
        $.ajax({
            type: "POST",
            url: "/module/eventory/dashboardajax",
            dataType: "json",
            data: {
                action: 'add-breakage',
                token: tokenAjaxAddBreakage,
                id_product: $(this).attr('data-id_product'),
                qty: qty,
            },
            success: function (response) {
                checkAjaxResponse(response);
                me.closest('tr').fadeOut();
            },
            error: function (request, status, error) {
                checkAjaxResponse(error);
            },
        });

    });

    $('#copyToken').click(function () {
        var tokenValue = $(this).data('value');
        var tempInput = $('<input>');
        $('body').append(tempInput);
        tempInput.val(tokenValue).select();
        document.execCommand('copy');
        tempInput.remove();
    });

    $(document).on('click', '#products tr td:nth-child(6)', function () {
        let cell = $(this);
        if (!cell.hasClass('edit-live-input') && !cell.hasClass('disabled')) {
            cell.addClass('not-after');
            let currentVal = cell.text();
            let inputHtml = '<input type="number" class="edit-live-input" value="' + currentVal + '"/> ';
            cell.html(inputHtml);

            let input = cell.find('input');
            input.focus().on('blur', function () {
                let newVal = $(this).val();
                if (newVal !== currentVal && newVal !== '') {
                    if (parseInt(newVal) >= 0) {
                        cell.text(newVal);
                        // Récupérer les valeurs de ean13 et qty
                        let ean13 = cell.parent().find('td:nth-child(3)').text();
                        let qty = cell.parent().find('td:nth-child(6)').text();

                        // Envoyer une requête AJAX uniquement si la valeur a changé
                        manageLoader('show');
                        $.ajax({
                            url: '/module/eventory/dashboardajax',
                            type: 'POST',
                            data: {
                                action: 'update-qty',
                                token: tokenAjaxUpdateQty,
                                ean13: ean13,
                                qty: qty
                            },
                            success: function (response) {
                                checkAjaxResponse(response)
                                cell.removeClass('not-after');
                                $('body').removeClass('overlay-active');
                            },
                            error: function (xhr, status, error) {
                                alert('ERROR: ' + error)
                                manageLoader('hide');
                                $('body').removeClass('overlay-active');
                            }
                        });
                    }
                } else {
                    cell.text(currentVal);
                    cell.removeClass('not-after');
                }
            });
            let inputTextLength = input.val().length;
            input[0].setSelectionRange(inputTextLength, inputTextLength);
            input.on('click', function (e) {
                e.stopPropagation();
            });
        }
    });

    if ($('#current_warehouse').length > 0) {
        document.getElementById('current_warehouse').addEventListener('change', function () {
            var selectedValue = this.value;
            var currentUrl = new URL(window.location.href);
            var params = new URLSearchParams(currentUrl.search);
            params.set('change_context_warehouse', selectedValue);
            currentUrl.search = params.toString();
            window.location.href = currentUrl.toString();
        });
    }

    //employee warehouse visibility in add form
    $(document).on('change', '#employee_administrator', function () {
        if ($(this).is(':checked')) {
            $('#employee_warehouse__cont').show();
            $('#employee_warehouse__cont select').prop('disabled', false);
        } else {
            $('#employee_warehouse__cont').hide();
            $('#employee_warehouse__cont select').prop('disabled', true);
        }
    });

});

$(document).on('click', '.ajax-generate-ean13', function (e) {
    manageLoader('show');
    // Proceed with AJAX request if validation passes
    $.ajax({
        type: "POST",
        url: "/ajaxcore",
        dataType: "json",
        data: {
            action: 'generate-ean',
        },
        success: function (response) {
            checkAjaxResponse(response);
        },
        error: function (request, status, error) {
            checkAjaxResponse(error);
        },
    });
})

$(document).on('change', '.active_warehouse', function () {
    me = $(this)
    manageLoader('show');
    $.ajax({
        url: '/module/eventory/dashboardajax',
        type: 'POST',
        data: {
            action: 'active-warehouse',
            token: tokenAjaxActiveWarehouse,
            active: me.is(':checked'),
            id_warehouse: me.attr('data-id-warehouse')
        },
        success: function (response) {
            checkAjaxResponse(response)
        },
        error: function (xhr, status, error) {
            alert('ERROR: ' + error)
            manageLoader('hide');
        }
    });
})

$(document).on('click', '#moreInformation', function () {
    $('#toggleContent').toggle('slow')
    $(this).hide()
    $('.hide-text-if-show').hide()
})

$(document).on('click', '.export_barecode', function (e) {
    manageLoader('show');
    var ean13 = $(this).attr('data-ean13');
    $.ajax({
        type: "POST",
        url: "/module/eventory/dashboardajax",
        dataType: "json",
        data: {
            action: 'render-ean13',
            ean13: ean13
        },
        success: function (response) {
            checkAjaxResponse(response)
            if (response && response.pdf_content_url) {
                var pdfUrl = response.pdf_content_url;
                var iframe = "<iframe width='100%' height='100%' src='" + pdfUrl + "'></iframe>";
                var x = window.open();
                x.document.open();
                x.document.write(iframe);
                x.document.close();
                //window.open(pdfUrl, '_blank');
            } else {
                alert('Failed to load PDF');
            }
        },
        error: function (request, status, error) {
            alert('AJAX Error: ' + error);
            manageLoader('hide');
        }
    });
});

$(document).on('click', '.resend_email_password', function (e) {
    manageLoader('show');
    var email = $(this).attr('data-email');
    $.ajax({
        type: "POST",
        url: "/module/eventory/dashboardajax",
        dataType: "json",
        data: {
            action: 'reset-password',
            email: email,
            token: tokenAjaxResetPassword,
        },
        success: function (response) {
            checkAjaxResponse(response)
        },
        error: function (request, status, error) {
            manageLoader('hide');
        }
    });
});

$(document).on('click', '.delete_employee', function (e) {
    manageLoader('show');
    var me = $(this);
    var id_employee = $(this).attr('data-id_employee');
    $.ajax({
        type: "POST",
        url: "/module/eventory/dashboardajax",
        dataType: "json",
        data: {
            action: 'delete-employee',
            token: tokenAjaxDeleteEmployee,
            id_employee: id_employee
        },
        success: function (response) {
            checkAjaxResponse(response)
            me.closest('tr').fadeOut();
        },
        error: function (request, status, error) {
            manageLoader('hide');
        }
    });
});


$(document).on('click', '.export_file', function (e) {
    manageLoader('show');
    var type = $(this).attr('data-type-file');
    $.ajax({
        type: "POST",
        url: "/ajaxcore",
        dataType: "json",
        data: {
            action: 'render-file',
            type_file: type,
        },
        success: function (response) {
            manageLoader('hide');
            if (response && response.file_content_url) {
                var pdfUrl = response.file_content_url;
                // Créer un lien de téléchargement
                var link = document.createElement('a');
                link.href = pdfUrl;
                link.download = 'catalog.' + response.type;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                alert('Failed to load File');
            }
        },

        error: function (request, status, error) {
            manageLoader('hide');
        }
    });
});

$(document).on('click', '.add_breakage', function () {
    console.log($(this).attr('data-id-product-attribute'))
    $('#ean13_breakage_ean13').val($(this).attr('data-ean13'));
    $('#ean13_breakage_name').val($(this).attr('data-name'));
    $('#ean13_breakage_product_quantity').val($(this).attr('data-quantity'));
    $('#ean13_breakage_quantity').val('').attr('max', $(this).attr('data-quantity'))
    $('#saveBreakage').attr('data-id_product', $(this).attr('data-id-product'));
})

// Validation function
function validateFormFields() {
    let isValid = true;
    $('#eventory-dashboard form:visible').find('input').each(function () {
        if (!$(this).val()) {
            $(this).css('border', '2px solid #c80e0e');
            isValid = false;
        } else {
            $(this).css('border', '');
        }
    });
    return isValid;
}


function checkAjaxResponse(response) {
    manageLoader('hide');
    try {
        response = JSON.parse(response);
    } catch (error) {
        response = response
    } if (response.success == true) {
        $('#success-alert').show();
        setTimeout(function () {
            $('#success-alert').hide("slow");
        }, 3000);
    } else {
        $('#error-alert').show();
        setTimeout(function () {
            $('#success-alert').hide("slow");
        }, 3000);
    }
}

function manageLoader(action = 'show') {
    if (action == 'show') {
        NProgress.start();
    } else {
        NProgress.done();
    }
}

function formatSelect2Product(product) {
    if (product.loading) {
        return product.text;
    }

    var $container = $(
        "<div style='display:flex;' class='select2-result-product'>" +
        "<img style='max-width:50px' src='" + product.image + "' />" +
        "<span style='margin-left:1em;'>" + product.text + "</span>" +
        "</div>"
    );

    return $container;
}

function formatSelect2ProductSelection(product) {
    return product.name;
}
